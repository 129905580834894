.check:after {
  height: 2px;
  display: block;
  width: 20px;
  background: #3ed400;
  border-bottom: 3px #3ed400;
  content: "";
}

.SearchInput {
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.HeaderShadowButton {
  box-shadow: 0px 4px 10px rgba(239, 169, 34, 0.36);
}

.LangSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */

  background: url("data:image/svg+xml,%3Csvg width='11' height='5' viewBox='0 0 11 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.30503 2.48603e-06H1.6924C1.55534 1.62253e-05 1.42136 0.0414186 1.30744 0.118968C1.19351 0.196518 1.10477 0.306725 1.05245 0.435633C1.00012 0.564542 0.986567 0.706351 1.0135 0.843096C1.04044 0.979852 1.10665 1.10539 1.20375 1.20383L4.53492 4.59286C4.79178 4.85358 5.13976 5 5.50258 5C5.86538 5 6.21337 4.85358 6.47023 4.59286L7.73685 3.30398L9.80075 1.20383C9.89672 1.10436 9.96169 0.978272 9.98752 0.841379C10.0134 0.704486 9.99888 0.562883 9.94595 0.434324C9.89301 0.305762 9.80394 0.195966 9.68993 0.118702C9.57591 0.0414383 9.44202 0.000145767 9.30503 2.48603e-06Z' fill='%2339342F'/%3E%3C/svg%3E")
    white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */

  /*For IE*/
}
.LangSelect::-ms-expand {
  display: none;
}

.PKR {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */

  background: url("data:image/svg+xml,%3Csvg width='24' height='9' viewBox='0 0 24 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_233_30)'%3E%3Cpath d='M7.164 3.10806C7.164 3.59606 7.052 4.04406 6.828 4.45206C6.604 4.85206 6.26 5.17606 5.796 5.42406C5.332 5.67206 4.756 5.79606 4.068 5.79606H2.796V8.82006H0.744V0.396057H4.068C4.74 0.396057 5.308 0.512057 5.772 0.744057C6.236 0.976057 6.584 1.29606 6.816 1.70406C7.048 2.11206 7.164 2.58006 7.164 3.10806ZM3.912 4.16406C4.304 4.16406 4.596 4.07206 4.788 3.88806C4.98 3.70406 5.076 3.44406 5.076 3.10806C5.076 2.77206 4.98 2.51206 4.788 2.32806C4.596 2.14406 4.304 2.05206 3.912 2.05206H2.796V4.16406H3.912ZM13.1163 8.82006L10.2843 5.10006V8.82006H8.23228V0.396057H10.2843V4.09206L13.0923 0.396057H15.5043L12.2403 4.52406L15.6243 8.82006H13.1163ZM20.8955 8.82006L19.1435 5.64006H18.6515V8.82006H16.5995V0.396057H20.0435C20.7075 0.396057 21.2715 0.512057 21.7355 0.744057C22.2075 0.976057 22.5595 1.29606 22.7915 1.70406C23.0235 2.10406 23.1395 2.55206 23.1395 3.04806C23.1395 3.60806 22.9795 4.10806 22.6595 4.54806C22.3475 4.98806 21.8835 5.30006 21.2675 5.48406L23.2115 8.82006H20.8955ZM18.6515 4.18806H19.9235C20.2995 4.18806 20.5795 4.09606 20.7635 3.91206C20.9555 3.72806 21.0515 3.46806 21.0515 3.13206C21.0515 2.81206 20.9555 2.56006 20.7635 2.37606C20.5795 2.19206 20.2995 2.10006 19.9235 2.10006H18.6515V4.18806Z' fill='%233ED400'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_233_30'%3E%3Crect width='24' height='9' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
    white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */

  /*For IE*/
}
.PKR::-ms-expand {
  display: none;
}

.slick-dots li.slick-active button:before {
  color: #3ed400 !important;
  font-size: 12px !important;
  /* font-size: 12px !important; */
}
.slick-dots li button:before {
  color: #39342f !important;
  font-size: 10px !important;
}
.slick-dots {
  bottom: 19px;
}

.slick-dots li button:hover:before {
  color: green !important;
}
/*
.RemoveSlickMargin > .slick-slider > .slick-list {
  margin: 0 !important;
  padding: 0 !important;
}
.RemoveSlickMargin > .slick-slider > .slick-list > .slick-track > .slick-slide {
  max-width: 100% !important;
}

@media (min-width: 429px) {
  .RemoveSlickMargin
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide {
    max-width: 100% !important;
  }
  .RemoveSlickMargin > .slick-slider > .slick-list {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 320px) {
  .RemoveSlickMargin > .slick-slider > .slick-list {
    margin: 0 !important;
    padding: 0 !important;
  }
}
*/

/* .slick-list {
  margin-left: 28px !important;
  padding: 0 50px 0 66px !important;
}

@media (min-width: 429px) {
  .slick-slide {
    max-width: 320px !important;
  }
  .slick-list {
    margin-left: 21px !important;
    padding: 0 50px 0 0 !important;
  }
}

@media (max-width: 320px) {
  .slick-list {
    margin-left: 40px !important;
    padding: 0 50px 0 0 !important;
  }
}

.slick-slide {
  max-width: 250px;
} */

/* for spacing between slides of slider 
@media (min-width: 880px) {
  .slick-slide {
    max-width: 320px !important;
  }
}
*/

/* 
removing ad slider from margin

.AdSliderStyle > .slick-slider > .slick-list > .slick-track > .slick-slide {
  max-width: 100% !important;
}

@media (min-width: 429px) {
  .AdSliderStyle > .slick-slider > .slick-list > .slick-track > .slick-slide {
    max-width: 100% !important;
  }
  .AdSliderStyle > .slick-slider > .slick-list {
    margin: 0 21px !important;
    padding: 0 50px 0 0 !important;
  }
}
*/

.spinner {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #3ed400;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

/* Styles for the select age slider */

.wrapper {
  /* position: relative;
    margin: 40px calc(16px / 2);
      height: calc(16px + 1.6rem);
  */
  display: flex;
  align-items: center;

  padding-top: 1.6rem;
}
.input-wrapper {
  width: calc(87% + 16px);
  margin: 0 calc(16px / -2);
  position: absolute;
  height: 16px;
}
.control-wrapper {
  width: 87%;
  position: absolute;
  height: 16px;
}
.input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;
}
.input::-ms-track {
  appearance: none;
  background: transparent;
  border: transparent;
}
.input::-moz-range-track {
  appearance: none;
  background: transparent;
  border: transparent;
}
.input:focus::-webkit-slider-runnable-track {
  appearance: none;
  background: transparent;
  border: transparent;
}
.input::-ms-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: #3ed400;
}
.input::-ms-thumb:active {
  cursor: grabbing;
}
.input::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: #3ed400;
}
.input::-moz-range-thumb:active {
  cursor: grabbing;
}
.input::-webkit-slider-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: #3ed400;
}
.input::-webkit-slider-thumb:active {
  cursor: grabbing;
}
.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.16);
}
.inner-rail {
  position: absolute;
  height: 100%;
  background: #3ed400;
}
.control {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background: #3ed400;

  top: 50%;
  margin-left: calc(16px / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
}

/* Styles for the select Weight Range slider */

.weightslider {
  width: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 10px;
}

label[for="weight"] {
  width: 39px;
  height: 50px;
  position: absolute;
  top: -55px;
  left: -12px;
  color: hsl(0, 0%, 0%);
  pointer-events: none;
}

label[for="weight"] span {
  position: absolute;
  top: 17px;
  left: 47%;
  text-align: center;
  font-size: 9px;
  font-weight: bold;
  transform: translateX(-50%);
  z-index: 5;
}

.weight[type="range"] {
  width: 100%;
}

.weight[type="range"] {
  color: #3ed400;
  --thumb-height: 20px;
  --track-height: 0.3em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

.weight[type="range"].weight-wrapper {
  color: #3ed400;
  --thumb-height: 20px;
  --track-height: 0.125em;
  --track-width: 100%;
}

/* === range commons === */
.weight[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

.weight[type="range"]:active {
  cursor: grabbing;
}

.weight[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
.weight[type="range"],
.weight[type="range"]::-webkit-slider-runnable-track,
.weight[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

.weight[type="range"]::-webkit-slider-runnable-track,
.weight[type="range"]::-webkit-slider-thumb {
  position: relative;
}

.weight[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

.weight[type="range"]:hover::-webkit-slider-thumb {
  cursor: grab;
}

.weight[type="range"]:active::-webkit-slider-thumb {
  cursor: grabbing;
}

.weight[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

.weight[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
.weight[type="range"],
.weight[type="range"]::-moz-range-track,
.weight[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

.weight[type="range"]::-moz-range-track,
.weight[type="range"]::-moz-range-thumb,
.weight[type="range"]::-moz-range-progress {
  background: #fff0;
}

.weight[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

.weight[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

.weight[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

.weight[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

.weight[type="range"]::-moz-range-track,
.weight[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

.weight[type="range"]::-moz-range-thumb,
.weight[type="range"]::-moz-range-progress {
  filter: brightness(100%);
}

.weight[type="range"]:hover::-moz-range-thumb,
.weight[type="range"]:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}

.weight[type="range"]:active::-moz-range-thumb,
.weight[type="range"]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

.weight[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

.back {
  background: url("data:image/svg+xml,%3Csvg width='286' height='235' viewBox='0 0 286 235' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_260_6' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='7' y='1' width='279' height='61'%3E%3Crect x='7' y='1' width='279' height='60.9874' rx='9' fill='%2339342F'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_260_6)'%3E%3Cpath d='M201.178 2.40201C207.02 2.40201 220.316 6.04723 226.765 20.6281C234.827 38.8543 237.631 40.9573 248.847 40.2563C260.063 39.5553 269.877 37.8028 271.279 46.5653C272.681 55.3279 275.134 60.5854 278.639 61.6369C281.443 62.4781 288.921 65.2588 292.309 66.544L302.824 52.8744' stroke='%23EFA922'/%3E%3Cpath d='M205.384 -5.30905C211.226 -5.30905 224.522 -1.66382 230.971 12.9171C239.033 31.1432 241.837 33.2462 253.053 32.5452C264.269 31.8442 274.083 30.0917 275.485 38.8543C276.887 47.6168 279.34 52.8744 282.845 53.9259C285.649 54.7671 293.127 57.5477 296.515 58.8329L307.03 45.1633' stroke='%23EFA922'/%3E%3Cpath d='M211.693 -13.0201C217.535 -13.0201 230.831 -9.37487 237.28 5.20604C245.342 23.4322 248.146 25.5352 259.362 24.8342C270.578 24.1332 280.392 22.3807 281.794 31.1432C283.196 39.9058 285.65 45.1633 289.155 46.2148C291.959 47.056 299.436 49.8367 302.824 51.1219L313.339 37.4523' stroke='%23EFA922'/%3E%3C/g%3E%3Crect opacity='0.15' width='286' height='235' rx='9' fill='%23EFA922'/%3E%3C/svg%3E%0A")
    no-repeat center 100%;
}

.slick-custom-dots {
  display: flex !important;
  margin-left: 20px;
  margin-top: 40px;
}

.slick-custom-dots li {
  margin-right: 10px;
}

.slick-active > a > img {
  filter: brightness(0.5);
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

#modal {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modalContent {
  width: 100%;
  height: 450px;

  background-color: #fff;

  padding: 20px;
  border-radius: 13px;
  margin: 0 auto;
  margin-top: -100px;
}

#modal:empty {
  display: none;
}

.AdSliderStyle > .slick-slider > .slick-list {
  margin: 0 21px !important;
  padding: 0 50px 0 0 !important;
}

.StylesCategorySeoContent h1,
.StylesCategorySeoContent h2,
.StylesCategorySeoContent h3,
.StylesCategorySeoContent h4,
.StylesCategorySeoContent h5,
.StylesCategorySeoContent h6 {
  font-family: "Poppins-Bold";
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #3ed400;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}

#zoomCheck[type="checkbox"] {
  display: none;
}

#zoomCheck[type="checkbox"]:checked ~ label > img {
  transform: scale(1.5);
  cursor: zoom-out;
}
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}
body::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.BtnShowWhenHoverCard:hover .opacity-0 {
  opacity: 1;
}

.QurbaniFormRadioBtn[type="radio"] {
  display: none;
}

.QurbaniFormRadioBtnLabel span {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 50px;
  transition: 0.25s ease;
  font-size: 12px;
  width: 100%;
}

.QurbaniFormRadioBtnLabel span:after {
  content: "";
  background-color: #f2f2f2;
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  //margin-left: 40px;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 2px #f2f2f2;
}

.QurbaniFormRadioBtn[type="radio"]:checked + span:after {
  border: 2px solid #3ed400;
  box-shadow: inset 0 0 0 2px #fff;
  background-color: #3ed400;
}

/* --------------- For Paymob Starts here   --------------- */

.PaymobFromRadioBtn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid gray;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}
.PaymobFromRadioBtnLabel {
  display: inline-flex;
  border-radius: 50px;
  transition: 0.25s ease;
  font-size: 12px;
  width: 100%;
}

.PaymobFromRadioBtn:checked {
  border: 2px solid #3ed400;
  box-shadow: inset 0 0 0 2px #fff;
  background-color: #3ed400;
}

/* --------------- For Paymob Ends here   --------------- */

.toast {
  position: fixed;
  top: 100px;
  right: 16px;
  padding: 16px;
  background-color: #3ed400;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.toast.show {
  opacity: 1;
}

.toast-message {
  margin-right: 16px;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 4px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}

.close-button:hover {
  color: #ddd;
}

.SearchLocationInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */

  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="%233ED400" width="20px" ><path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" /></svg>')
    white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */

  /*For IE*/
}
.SearchLocationInput ::-ms-expand {
  display: none;
}
