body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@layer components {
  table {
    @apply min-w-full divide-y divide-gray-300 overflow-x-auto;
  }
  thead {
    @apply bg-gray-50;
  }
  th {
    @apply py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6;
  }
  tbody {
    @apply divide-y divide-gray-200 bg-white;
  }
  tbody {
    @apply divide-y divide-gray-200 bg-white;
  }
  td {
    @apply whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6;
  }

  .StylesCategorySeoContent ul {
    @apply list-disc list-inside;
  }

  .StylesCategorySeoContent ol {
    @apply list-decimal list-inside;
  }
  .StylesCategorySeoContent ol > li::marker {
    font-weight: bold;
  }

  .StylesCategorySeoContent p {
    @apply text-justify;
  }
  .StylesCategorySeoContent ul li p,
  .StylesCategorySeoContent ol li p {
    @apply inline;
  }
  .StylesCategorySeoContent h1 {
    @apply text-2xl;
  }
  .StylesCategorySeoContent h2 {
    @apply text-xl;
  }
  .StylesCategorySeoContent h3 {
    @apply text-lg;
  }
  .StylesCategorySeoContent h4 {
    @apply text-base;
  }

  /* ... */
}
